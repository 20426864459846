import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryCache, QueryClientProvider } from 'react-query';

// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { TOKEN_KEY } from 'Shared/token';
// routes
import Router, { NotLoggedInRoutes } from './routes';
// theme
import ThemeProvider from './theme';
import { useSelector } from 'react-redux';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: async (error, query) => {
      if (error.request.status === 401) {
        localStorage.removeItem(TOKEN_KEY);
        window.location.reload();
      }
    },
  }),
});

// ----------------------------------------------------------------------

export default function App() {
  const isLoggin = useSelector((state) => state.login.isLogin);
  console.log(isLoggin);
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <Toaster />
            <ScrollToTop />
            <StyledChart />
            {isLoggin ? <Router /> : <NotLoggedInRoutes />}
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
