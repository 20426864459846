import { baseUrl } from 'Shared/baseUrl';
import { TOKEN_KEY } from 'Shared/token';
import axios from 'axios';
import { getMyToken } from 'helpers/request_helper';
import { useQuery } from 'react-query';

export default function useCategories(page: number, pageSize: number, search: string) {
  return useQuery(['categories', page + ' ' + pageSize + ' ' + search], () =>
    axios.get(baseUrl + `category?page=${page}&pageSize=${pageSize}&search=${search}`, {
      headers: {
        Authorization: getMyToken(),
      },
    })
  );
}
