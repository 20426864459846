import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import AddCategoryModal from '../components/categories';
import useCategories from 'hooks/useCategories';
import { Category } from '../interface/category';
import { intlFormat } from 'date-fns';
import EditCategoryModal from 'components/categories/edit_category';
import ConfirmationModal from 'components/common/confirmationModal';
import { ApiRequest } from 'helpers/request_helper';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'category_id', label: 'ID', alignRight: false },
  { id: 'title', label: 'Category Title', alignRight: false },
  { id: 'image_url', label: 'Image', alignRight: false },
  { id: 'created_at', label: 'Created At', alignRight: false },
  { id: 'updated_at', label: 'UpdatedAt', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CategoryPage() {
  const [open, setOpen] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [page, setPage] = useState(1);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      var res = await ApiRequest.delete(`category/${selectedCategory.category_id}`);
      if (res.status === 200) {
        refetch();
        setIsDelete(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const { isLoading, error, data, isFetching, refetch } = useCategories(page, rowsPerPage);
  const formattedDate = (date) =>
    Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(date));

  return (
    <>
      <Helmet>
        <title>Categories | QuizHunt</title>
      </Helmet>
      {/* <AddCategoryModal */}

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Categories
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add New Category
          </Button> */}
          <AddCategoryModal />
        </Stack>
        <EditCategoryModal
          open={isEdit}
          category={selectedCategory}
          onClose={() => {
            setIsEdit(false);
            setSelectedCategory(null);
          }}
        />
        <ConfirmationModal
          open={isDelete}
          title="Delete Category"
          description="Are you sure you want to delete this category?"
          onClose={() => {
            setIsDelete(false);
            setSelectedCategory(null);
          }}
          isLoading={isDeleting}
          onConfirm={handleDelete}
        />

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {data && (
                  <TableBody>
                    {data.data.data.map((category) => {
                      const { category_id, title, image_url, created_at, updated_at } = category;
                      const selectedCategory = selected.indexOf(category_id) !== -1;

                      return (
                        <>
                          <TableRow hover key={category_id} tabIndex={-1} role="checkbox" selected={selectedCategory}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedCategory}
                                onChange={(event) => handleClick(event, category_id)}
                              />
                            </TableCell>

                            <TableCell align="left">{category_id}</TableCell>

                            <TableCell align="left" scope="row">
                              <Typography variant="subtitle2" noWrap>
                                {title}
                              </Typography>
                            </TableCell>

                            <TableCell align="left">
                              <Avatar
                                alt={title}
                                src={image_url}
                                sx={{ aspectRatio: '16/9', height: 100, width: 178, borderRadius: 0 }}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="subtitle2" noWrap>
                                {formattedDate(created_at)}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="subtitle2" noWrap>
                                {formattedDate(updated_at)}
                              </Typography>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(e) => {
                                  setSelectedCategory(category);
                                  handleOpenMenu(e);
                                }}
                              >
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                              <Popover
                                open={Boolean(open)}
                                anchorEl={open}
                                onClose={handleCloseMenu}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                PaperProps={{
                                  sx: {
                                    p: 1,
                                    width: 140,
                                    '& .MuiMenuItem-root': {
                                      px: 1,
                                      typography: 'body2',
                                      borderRadius: 0.75,
                                    },
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={(v) => {
                                    setIsEdit(true);
                                    setOpen(null);
                                  }}
                                >
                                  <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                  Edit
                                </MenuItem>

                                <MenuItem
                                  onClick={() => {
                                    setIsDelete(true);
                                    setOpen(null);
                                  }}
                                  sx={{ color: 'error.main' }}
                                >
                                  <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                                  Delete
                                </MenuItem>
                              </Popover>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.data?.meta?.totalCategories || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
