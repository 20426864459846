const currentURL = window.location.hostname;

var isDebug = false;

// export const baseUrl = `http://quizhunt.ap-south-1.elasticbeanstalk.com/`;
//if domain is localhost then use local url

console.log(currentURL);
if (currentURL === 'localhost') {
  isDebug = true;
}

export const debugUrl = `http://localhost:5000/`;
export const baseUrl = isDebug ? debugUrl : 'https://quizhunt.suraj1.com.np/';

export const imgUrl = ``;
