import { createSlice } from '@reduxjs/toolkit';
import { TOKEN_KEY } from 'Shared/token';

const userToken = localStorage.getItem(TOKEN_KEY);
console.log('userToken', userToken);

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isLogin: userToken ? true : false,
    userToken: userToken ? userToken : '',
  },
  reducers: {
    login: (state, action) => {
      state.isLogin = true;
      state.userToken = action.payload;
    },
    logout: (state) => {
      state.isLogin = false;
      state.userToken = '';
    },
  },
});

export const { login, logout } = loginSlice.actions;
export default loginSlice.reducer;
