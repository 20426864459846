import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useForm } from 'react-hook-form';
import { ApiRequest } from 'helpers/request_helper';
import { TOKEN_KEY } from 'Shared/token';
import { useDispatch } from 'react-redux';
import { login } from 'redux/checkLoginSlice';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = async () => {
    try {
      console.log('handleClick');
      console.log(getValues());
      const res = await ApiRequest.post('auth/login', getValues());

      if (res.status === 200) {
        localStorage.setItem(TOKEN_KEY, JSON.stringify(res.data.data));
        dispatch(login(res.data.data));
        navigate('/dashboard', { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    criteriaMode: 'firstError',
  });

  return (
    <>
      <Stack spacing={3}>
        <TextField
          id="email"
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid email address format',
            },
          })}
          name="email"
          label="Email address"
        />

        <TextField
          id="password"
          name="password"
          label="Password"
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          {...register('password', {
            required: 'Password is required',
            minLength: {
              value: 8,
              message: 'Password should be at least 8 characters',
            },
          })}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit(handleClick)}>
        Login
      </LoadingButton>
    </>
  );
}
