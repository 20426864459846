import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

// const firebaseConfig = {
//   apiKey: process.env.FIREBASE_APIKEY,
//   authDomain: process.env.FIREBASE_AUTHDOMAIN,
//   projectId: process.env.FIREBASE_PROJECTID,
//   storageBucket: process.env.FIREBASE_STORAGEBUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
//   appId: process.env.FIREBASE_APPID,
//   measurementId: process.env.FIREBASE_MEASUREMENTID,
// };

const firebaseConfig = {
  apiKey: 'AIzaSyCj22aZLwDONThwLakBmbtR9T4NeyqvSig',
  authDomain: 'quizhunt-e659d.firebaseapp.com',
  projectId: 'quizhunt-e659d',
  storageBucket: 'quizhunt-e659d.appspot.com',
  messagingSenderId: '404733631134',
  appId: '1:404733631134:web:be518e7791189f0de6398f',
  measurementId: 'G-YKHFJVTYNH',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseStorage = getStorage(app);

async function handleImageUpload(file: File, location: string): Promise<string> {
  return new Promise((resolve, reject): string => {
    var id = uuidv4();
    const storageRef = ref(firebaseStorage, location);
    const imageRef = ref(storageRef, id);

    const uploadTask = uploadBytesResumable(imageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');

        switch (snapshot.state) {
          case 'paused':
            break;
          case 'running':
            break;
          default:
            break;
        }
      },
      (error) => {
        reject(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        resolve(downloadURL);
      }
    );
    return '';
  });
}

export default handleImageUpload;
