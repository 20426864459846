import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import AddCategoryModal from '../components/categories';
import useCategories from 'hooks/useCategories';
import { Category } from '../interface/category';
import { intlFormat } from 'date-fns';
import useUsers from 'hooks/useUsers';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user_id', label: 'ID', alignRight: false },
  { id: 'user', label: 'User', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'provider', label: 'Provider', alignRight: false },

  { id: 'created_at', label: 'Created At', alignRight: false },
  //is_admin
  { id: 'is_admin', label: 'Admin status', alignRight: false },
  //is email verified
  { id: 'is_email_verified', label: 'Email Verified', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(1);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const { isLoading, error, data, isFetching, refetch } = useUsers(page, rowsPerPage);
  const formattedDate = (date) => {
    if (!date) return 'invalid date';
    return Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(date));
  };

  return (
    <>
      <Helmet>
        <title>Users | QuizHunt</title>
      </Helmet>
      {/* <AddCategoryModal */}

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add New Category
          </Button> */}
          <AddCategoryModal />
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {data && (
                  <TableBody>
                    {data.data.data.map((category) => {
                      const {
                        user_id,
                        provider,
                        email_verified_at,
                        is_admin,
                        full_name,
                        email,
                        avatar,
                        created_at,
                        updated_at,
                      } = category;
                      const selectedUser = selected.indexOf(user_id) !== -1;

                      return (
                        <TableRow hover key={user_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, user_id)} />
                          </TableCell>

                          <TableCell align="left">{user_id}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={full_name} src={avatar} />
                              <Typography variant="subtitle2" noWrap>
                                {full_name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left" scope="row">
                            <Typography variant="subtitle2" noWrap>
                              {email}
                            </Typography>
                          </TableCell>

                          {/* <TableCell align="left">
                            <Avatar
                              alt={title}
                              src={avatar}
                              sx={{ aspectRatio: '16/9', height: 100, width: 178, borderRadius: 0 }}
                            />
                          </TableCell> */}

                          {
                            //if email show email else google then google and their icons
                            provider === 'google' ? (
                              <TableCell align="left">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Iconify icon={'logos:google-icon'} width={20} height={20} />
                                  <Typography variant="subtitle2" noWrap>
                                    {sentenceCase(provider)}
                                  </Typography>
                                </Stack>
                              </TableCell>
                            ) : (
                              <TableCell align="left">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Iconify icon={'fluent:mail-28-filled'} width={20} height={20} />
                                  <Typography variant="subtitle2" noWrap>
                                    {sentenceCase(provider)}
                                  </Typography>
                                </Stack>
                              </TableCell>
                            )
                          }

                          <TableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                              {formattedDate(created_at)}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">{is_admin == 1 ? 'Yes' : 'No'}</TableCell>

                          <TableCell align="left">
                            <Label color={(!email_verified_at && 'error') || 'success'}>
                              {(!email_verified_at && 'Non-Verified') || 'Verified'}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.data?.meta?.totalUsers || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
