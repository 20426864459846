import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  isLoading?: boolean;
}

const ConfirmationModal: React.FC<Props> = ({ open, onClose, onConfirm, title, description, isLoading }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <div>
      <Dialog
        onClose={() => {
          if (!isLoading) {
            onClose();
          }
        }}
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton loading={loading} onClick={onConfirm}>
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationModal;
