import toast from 'react-hot-toast';
export const createNotification = (type: NotificationType, message: string) => {
  switch (type) {
    case NotificationType.info:
      toast(message);
      break;
    case NotificationType.success:
      toast.success(message, {
        duration: 2000,
      });
      break;
    case NotificationType.warning:
      toast.error(message);
      break;
    case NotificationType.error:
      toast.error(message);
      break;
    default:
      toast(message);
      break;
  }
};

export enum NotificationType {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}
