import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';

import React from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FieldValues, useForm } from 'react-hook-form';
import handleImageUpload from 'controllers/firebase_storage_controller';
import { ApiRequest } from 'helpers/request_helper';
import { useQueryClient } from 'react-query';
import { set } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { is } from 'date-fns/locale';
import { Category } from 'interface/category';

interface Props {
  onClose: () => void;
  open: boolean;
  category: Category;
}

const EditCategoryModal: React.FC<Props> = ({ onClose, open, category }) => {
  const [loading, setLoading] = React.useState(false);
  const queryClient = useQueryClient();
  const [existingImage, setExistingImage] = React.useState<string | null>(null);

  const [image, setImage] = React.useState<string | null>(null);
  const imageRef = React.useRef<HTMLInputElement>(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      title: '',
      imageUrl: '',
    },
    criteriaMode: 'firstError',
  });

  React.useEffect(() => {
    if (!category) return;
    setValue('title', category.title);
    setExistingImage(category.image_url ?? null);
  }, [category]);

  const onSave = async (data: FieldValues) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('title', data.title);
      if (image) {
        const imageUrl = await handleImageUpload(imageRef.current!.files![0], 'categories');
        formData.append('image_url', imageUrl);
      }
      const res = await ApiRequest.put(`category/${category.category_id}`, formData);
      if (res.status === 200) {
        queryClient.invalidateQueries('categories');
        onClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open form dialog
        </Button> */}

      <Dialog open={open} onClose={onClose} fullWidth>
        <FormControl fullWidth>
          <DialogTitle> {'Edit Category'}</DialogTitle>

          <DialogContent>
            <DialogContentText>{'Edit Category'}</DialogContentText>
            <TextField
              autoFocus
              inputProps={{
                inputMode: 'text',
              }}
              error={!!errors.title}
              helperText={errors.title?.message?.toString()}
              {...register('title', {
                required: 'Category name is required',
              })}
              margin="normal"
              id="name"
              label="Enter Category Name"
              type="text"
              fullWidth
              variant="outlined"
            />

            <FormLabel
              style={{
                marginTop: '15px',
                marginBottom: '10px',
              }}
              component="legend"
            >
              Upload Category Image
            </FormLabel>
            {!image && (
              <Button
                variant="outlined"
                style={{
                  display: 'block',
                  marginBottom: '10px',
                }}
                onClick={() => {
                  imageRef.current?.click();
                }}
              >
                Upload New Image
              </Button>
            )}

            <input
              hidden
              type="file"
              //   {...register('imageUrl', {
              //     required: 'Category image is required',
              //   })}
              ref={imageRef}
              accept="image/*"
              onChange={(e) => {
                setImage(URL.createObjectURL(e.target.files![0]));
                setValue('imageUrl', e.target.files![0]);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && imageRef.current) {
                  imageRef.current.click();
                }
              }}
              required
            />
            {errors.imageUrl && !image && (
              // @ts-ignore
              <p style={{ color: 'red' }}>{errors.imageUrl?.message?.toString()}</p>
            )}
            {image && (
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  border: '1px solid #ccc',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={image}
                  alt="category"
                  style={{
                    width: '90%',

                    aspectRatio: '16/9',
                    objectFit: 'cover',
                    marginTop: '10px',
                  }}
                />
                <AiOutlineCloseCircle
                  size={30}
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '1%',
                    right: '1%',
                    color: '#fff',
                    backgroundColor: 'red',
                    // backgroundColor: 'red',
                    borderRadius: '50%',
                    padding: '5px',
                  }}
                  onClick={() => {
                    setImage(null);
                    setValue('imageUrl', null);
                  }}
                />
              </div>
            )}
            {existingImage && !image && (
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  border: '1px solid #ccc',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={existingImage}
                  alt="category"
                  style={{
                    width: '90%',

                    aspectRatio: '16/9',
                    objectFit: 'cover',
                    marginTop: '10px',
                  }}
                />
              </div>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            {/* <LoadingButton loading /> */}
            <LoadingButton loading={loading} type="submit" variant="contained" onClick={handleSubmit(onSave)}>
              Edit
            </LoadingButton>
          </DialogActions>
        </FormControl>
      </Dialog>
    </div>
  );
};

export default EditCategoryModal;
