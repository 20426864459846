import { baseUrl } from 'Shared/baseUrl';
import { TOKEN_KEY } from 'Shared/token';
import axios from 'axios';
import { Dispatch } from 'react';
import toast from 'react-hot-toast';

import { NotificationType, createNotification } from 'utils/notification_manager';

export class ApiRequest {
  static get(url: string) {
    return axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY),
      },
    });
  }

  static post = async (url: string, data: FormData | null, dispatch?: Dispatch<any>) => {
    var response = await axios({
      method: 'POST',
      url: baseUrl + url,
      data: data,
      headers: {
        Authorization: getMyToken(),
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          createNotification(NotificationType.success, res.data.message);
          return { data: res.data, err: null, status: res.status };
        } else {
          return { data: null, err: res.data.message, status: res.status };
        }
      })
      .catch((e) => {
        var data = e.response.data;
        if (e.response.status === 401) {
        }
        if (e.response.status === 400) {
          console.log('data', data);
          toast.error(data.message);
          // createNotification(NotificationType.error, data.message);
        } else {
          createNotification(NotificationType.error, e.message);
        }

        return { data: data, err: data.message, status: e.response.status };
      });
    return response;
  };

  static put = async (url: string, data: FormData | null, dispatch?: Dispatch<any>) => {
    var response = await axios({
      method: 'PUT',
      url: baseUrl + url,
      data: data,
      headers: {
        Authorization: getMyToken(),
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          createNotification(NotificationType.success, res.data.message);
          return { data: res.data, err: null, status: res.status };
        } else {
          return { data: null, err: res.data.message, status: res.status };
        }
      })
      .catch((e) => {
        var data = e.response.data;
        if (e.response.status === 401) {
          //   dispatch(logoutUser());
        }
        if (e.response.status === 400) {
          createNotification(NotificationType.error, data.message);
        } else {
          createNotification(NotificationType.error, e.message);
        }
        return { data: data, err: data.message, status: e.response.status };
      });
    return response;
  };

  static delete = async (url: string, dispatch: Dispatch<any>) => {
    var response = await axios({
      method: 'DELETE',
      url: baseUrl + url,
      headers: {
        Authorization: getMyToken(),
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          createNotification(NotificationType.success, res.data.message);
          return { data: res.data, err: null, status: res.status };
        } else {
          return { data: null, err: res.data.message, status: res.status };
        }
      })
      .catch((e) => {
        var data = e.response.data;
        if (e.response.status === 401) {
          //   dispatch(logoutUser());
        }
        createNotification(NotificationType.error, e.message);
        return { data: data, err: data.message, status: e.response.status };
      });
    return response;
  };

  // switch case class for status codes
  static getStatusCode(statusCode: number) {
    switch (statusCode) {
      case 200:
        return 'OK';
      case 201:
        return 'Created';
      case 202:
        return 'Accepted';
      case 204:
        return 'No Content';
      case 400:
        return 'Bad Request';
      case 401:
        return 'Unauthorized';
      case 403:
        return 'Forbidden';
      case 404:
        return 'Not Found';
      case 500:
        return 'Internal Server Error';
      default:
        return 'Unknown';
    }
  }
}

export var getMyToken = () => {
  try {
    return 'Bearer ' + JSON.parse(localStorage.getItem(TOKEN_KEY) ?? '').token;
  } catch (error) {
    return '';
  }
};
